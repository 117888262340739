.StatisticsModule {
  &__line-icon {
    height: 0.3125rem;
    border-bottom: 3px solid color('lilac');

    @include media('md-up') {
      border-bottom: 5px solid color('lilac');
    }
  }

  &__paragraph p a {
    color: color('chalk');
  }

  &__fact {
    @include media('md-up') {
      &:nth-of-type(3n + 1) {
        margin-left: 0;
      }

      &:nth-of-type(3n + 2) {
        margin-left: 20%;
      }

      &:nth-of-type(3n + 3) {
        margin-left: 10%;
      }

      &:nth-of-type(3n + 4) {
        margin-left: 35%;
      }
    }
  }
}

//Adds max width to the right column of facts
.fact-max-width {
  max-width: 32.5rem;
}
