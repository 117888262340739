.HeroTextModule {
  &__title {
    width: 8rem;

    @include media('md-up') {
      width: 12rem;
    }
  }

  &__heading p {
    @extend .hyphens;

    @include media('sm-up') {
      hyphens: none;
    }
  }

  &__heading p,
  &__intro-byline p {
    display: inline;
  }

  &--style-nutella,
  &--style-lilac {
    &__heading p a,
    &__intro-byline p a {
      @extend .transition-shorter;

      color: color('charcoal');

      &:hover {
        color: color('chalk');
      }
    }
  }

  &--style-mulberry {
    &__heading p a,
    &__intro-byline p a {
      @extend .transition-shorter;
      color: color('chalk');

      &:hover {
        color: color('lilac');
      }
    }
  }
}
