$z: (
  'below': -1,
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  'overlay': 600,
  'overlay-menu': 800,
  'nav': 900,
);

@function z($index) {
  @return map-get($z, $index);
}

@each $name, $z-index in $z {
  .z-#{$name} {
    z-index: #{$z-index};
  }
}
