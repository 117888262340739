.HomeHero {
  &__logo {
    width: $home-hero-logo-width-mobile;

    @include media('md-up') {
      width: $home-hero-logo-width-desktop;
    }
  }

  &__logo-container {
    &--style-is-active {
      animation: $transition-animate-appear-from-left;
      animation-fill-mode: forwards;
    }
  }

  &__photo-card {
    top: 3rem;
    right: -6rem;

    @include media('md-up') {
      top: 7rem;
      right: -5rem;
    }
  }
}
