html {
  background-color: color('chalk');
}

/** Borders */

.border-top-nutella {
  border-top: 3px solid color('nutella');
}

.border-bottom-nutella {
  border-bottom: 3px solid color('nutella');
}

.border-top-mulberry {
  border-top: 3px solid color('mulberry');
}

.border-bottom-mulberry {
  border-bottom: 3px solid color('mulberry');
}

.border-top-lilac {
  border-top: 3px solid color('lilac');
}

.border-bottom-lilac {
  border-bottom: 3px solid color('lilac');
}

/** Global site sizing */

.site-inner-content-max-width {
  max-width: $site-inner-content-max-width;
}

.site-padding-x {
  padding-left: $site-padding-x-mobile;
  padding-right: $site-padding-x-mobile;

  @include media('lg-up') {
    padding-left: $site-padding-x-desktop;
    padding-right: $site-padding-x-desktop;
  }
}

.site-margin-x {
  margin-left: $site-padding-x-mobile;
  margin-right: $site-padding-x-mobile;

  @include media('lg-up') {
    margin-left: $site-padding-x-desktop;
    margin-right: $site-padding-x-desktop;
  }
}

.site-padding-x-left {
  padding-left: $site-padding-x-mobile;

  @include media('lg-up') {
    padding-left: $site-padding-x-desktop;
  }
}

// Default Pages and Home Page
.page-style--margin-top {
  margin-top: calc(#{$nav-top-margin-mobile} + #{$nav-height-mobile});

  @include media('xl-up') {
    margin-top: $nav-height-desktop;
  }
}

/** General Link Styling */

.link--style-body-charcoal {
  color: color('charcoal');
  @extend .transition-shorter;
  @extend .text-decoration-none;
  @extend .hover-lighten-charcoal;
  @extend .secondary-bold-sm;
}

/** Default Page */

.default-page-inner-text {
  margin-left: $site-padding-x-mobile;
  margin-right: $site-padding-x-mobile;

  @include media('md-up') {
    width: 50%;
    margin: 0 auto;
  }

  ul,
  ol {
    list-style-position: inside;
  }

  ul {
    list-style-type: none;

    li {
      background-image: url('../components/icons/BulletPointIcon.svg');
      background-repeat: no-repeat;
      background-position: 1px;
      padding-left: 3.375rem;
    }
  }

  ol li a,
  ul li a {
    @extend .link--style-body-charcoal;
  }

  ol li::before {
    content: '';
    width: 2.375rem;
    display: inline-block;
  }
}

/** Utils */

.text-module-padding-x {
  @extend .site-margin-x;
  @extend .site-inner-content-max-width;
}

.disable-body-scroll {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.hyphens {
  hyphens: auto;

  &--style-mobile {
    hyphens: auto;

    @include media('md-up') {
      hyphens: none;
    }
  }
}

.two-columns {
  @include media('md-up') {
    column-count: 2;
    column-gap: 3rem;
  }
}
