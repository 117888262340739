/** 
  Times_Now_ExtraLightSansComp - Primary
*/
@font-face {
  font-family: 'UniversLTPro-Ex';
  src: url('/assets/fonts/UniversLTPro-Ex.woff2') format('woff2'),
    url('/assets/fonts/UniversLTPro-Ex.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/** 
  ABCDiatype - Secondary
*/

@font-face {
  font-family: 'Times_Now_ExtraLight';
  src: url('/assets/fonts/Times_Now_ExtraLight.woff2') format('woff2'),
    url('/assets/fonts/Times_Now_ExtraLight.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UniversLTPro-BoldExt';
  src: url('/assets/fonts/UniversLTPro-BoldExt.woff2') format('woff2'),
    url('/assets/fonts/UniversLTPro-BoldExt.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
