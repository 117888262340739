.MenuOverlay {
  &__section-one {
    height: 2.9375rem;

    @include media('lg-up') {
      height: unset;
      transform: translate3d(-10rem, 0, 0);

      &--active {
        transform: translate3d(0rem, 0, 0);
      }
    }
  }

  &__section-two {
    flex-grow: 1;
    transform: translate3d(0, -10rem, 0);

    &--active {
      transform: translate3d(0, 0, 0);
    }

    @include media('lg-up') {
      padding-left: 5%;
      transform: translate3d(-30rem, 0, 0);

      &--active {
        transform: translate3d(0rem, 0, 0);
      }
    }
  }

  &__section-three {
    flex-grow: 1;
    transform: translate3d(0, -20rem, 0);

    &--active {
      transform: translate3d(0, 0, 0);
    }

    @include media('lg-up') {
      transform: translate3d(-40rem, 0, 0);

      &--active {
        transform: translate3d(0rem, 0, 0);
      }
    }
  }

  &__newsletter {
    transform: translate3d(0, -30rem, 0);

    &--active {
      transform: translate3d(0, 0, 0);
    }

    @include media('lg-up') {
      transform: translate3d(0, 0, 0);
    }
  }

  &__heading {
    p {
      display: inline;
    }

    &--active {
      animation: animate-fade-in 0.5s forwards 0.25s;
    }
  }

  &__newsletter-inner-container {
    &--active {
      animation: animate-fade-in 0.5s forwards 0.25s;
    }

    &--style-mobile {
      &--active {
        animation: animate-fade-in 0.5s forwards 0.45s;
      }
    }
  }

  &__heading-indentation {
    @include media('md-up') {
      padding-right: 13.9375rem;
    }
  }

  &__section-two-inner-container {
    &--active {
      animation: animate-fade-in 0.5s forwards 0.3s;
    }
  }

  &__section-three-inner-container {
    &--active {
      animation: animate-fade-in 0.5s forwards 0.35s;
    }

    @include media('lg-up') {
      padding-left: 10%;
    }

    @include media('xl-up') {
      padding-left: 13%;
    }
  }

  &__copyright {
    bottom: 0;

    @include media('lg-up') {
      padding-left: 10%;

      &--active {
        animation: animate-fade-in 0.5s forwards 0.35s;
      }
    }

    @include media('xl-up') {
      padding-left: 13%;
    }
  }

  &__menu-link-button {
    &--style-medium {
      &::before {
        content: '';
        @extend .transition-shorter;
        background-image: url('../../components/icons/DefaultHeadingLineIcon.svg');
        display: inline-block;
        width: 0;
        opacity: 0;
      }

      &:hover {
        &::before {
          @extend .transition-shorter;
          opacity: 1;
          width: 1.5rem;
          height: 0.313rem;
          margin-right: 0.4rem;

          @include media('lg-up') {
            width: 1.9375rem;
            height: 0.4375rem;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
