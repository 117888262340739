$colors: (
  'transparent': rgba(255, 255, 255, 0),
  'white': #fecea4,
  'chalk': #fff3e3,
  'lilac-lightest': #f2dedc,
  'lilac-lighter': #fecea4,
  'lilac': #ffb2b2,
  'lilac-darker': #fdfc71,
  'lilac-darkest': #0055dd,
  'lilac-very-dark': #2d77fc,
  'mulberry': #000000,
  'nutella': rgb(255, 84, 22),
  'nutella-darker': #ffb34d,
  'charcoal': #242424,
  'black-lighter': rgba(0, 0, 0, 0.5),
  'black': #000000,
);
