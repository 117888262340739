$site-max-width: 118.75rem;
$site-inner-content-max-width: 111.25rem;
$site-padding-x-mobile: 0.75rem;
$site-padding-x-desktop: 3.75rem;

/** Home */
$home-hero-logo-width-desktop: 15rem;
$home-hero-logo-width-mobile: 9rem;

/** Nav */
$nav-logo-width-mobile: 9rem;
$nav-logo-width-tablet: 9rem;
$nav-logo-width-desktop: 9rem;

$nav-top-margin-mobile: 2.5rem;
$nav-top-margin-tablet: 2.5rem;

$nav-height-mobile: 4rem;
$nav-height-tablet: 4rem;
$nav-height-desktop: 4rem;

/** Footer */
$footer-height-desktop: 32.125rem;

/** Cookie Consent */
$cookie-consent-width: 20.9375rem;
