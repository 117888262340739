$univers: 'Times_Now_ExtraLight', 'Times New Roman', serif;
$univers-bold: 'UniversLTPro-BoldExt', 'Arial', sans-serif;
$times: 'UniversLTPro-Ex', 'Arial', sans-serif;

/** Families */
.font-primary {
  font-family: $times;
}

.font-secondary {
  font-family: $univers;
}

.font-secondary-bold {
  font-family: $univers-bold;
}

/** Styles */

.font-italic {
  font-style: italic;
}

/** Sizes */

.primary-xxl {
  font-family: $times;
  font-size: 3.125rem;
  line-height: 3.125rem;

  @include media('lg-up') {
    font-size: 6.25rem;
    line-height: 6.25rem;
  }
}

.primary-xl {
  font-family: $times;
  font-size: 2.25rem;
  line-height: 2.3625rem;

  @include media('lg-up') {
    font-size: 4.5rem;
    line-height: 4.725rem;
  }
}

.primary-lg {
  font-family: $times;
  font-size: 2.25rem;
  line-height: 2.3625rem;

  @include media('lg-up') {
    font-size: 3.375rem;
    line-height: 3.54375rem;
  }
}

.primary-md {
  font-family: $times;
  font-size: 1.5rem;
  line-height: 1.65rem;

  @include media('lg-up') {
    font-size: 2.25rem;
    line-height: 2.3625rem;
  }
}

.primary-sm {
  font-family: $times;
  font-size: 0.875rem;
  line-height: 0.91875rem;
  letter-spacing: 0.0125rem;

  @include media('lg-up') {
    font-size: 1.125rem;
    line-height: 1.18125rem;
  }
}

.primary-xs {
  font-family: $times;
  font-size: 0.875rem;
  line-height: 0.91875rem;
}

.secondary-sm {
  font-family: $univers;
  font-size: 0.875rem;
  line-height: 1.0625rem;

  @include media('lg-up') {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}

.secondary-xs {
  font-family: $univers;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: 0;
}

.secondary-xxs {
  font-family: $univers;
  font-size: 0.625rem;
  line-height: 0.8125rem;
  letter-spacing: 0.01rem;
}

.secondary-bold-sm {
  font-family: $univers-bold;
  font-size: 0.875rem;
  line-height: 1.0625rem;

  @include media('lg-up') {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}

.secondary-bold-xs {
  font-family: $univers-bold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: 0;
}

.secondary-bold-xxs {
  font-family: $univers-bold;
  font-size: 0.625rem;
  line-height: 0.8125rem;
  letter-spacing: 0.01rem;
}
