.PortfolioListing {
  @include media('lg-up') {
    padding-left: $site-padding-x-desktop;
    padding-right: $site-padding-x-desktop;
  }

  &__company-container {
    @include media('md-up') {
      height: 16.875rem;

      &:hover,
      &:focus {
        .PortfolioListing__card--style-header {
          background-color: rgba(205, 212, 253, 0.55);
        }

        .PortfolioListing__founders-container {
          &::before {
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;
            opacity: 1;
            background-color: rgba(205, 212, 253, 0.5);
            background-blend-mode: overlay;
            border-radius: 0.35rem;
          }
        }
      }
    }
  }

  &__card {
    &--style-header {
      width: 100%;

      @include media('md-up') {
        justify-content: unset;
        width: 12rem;
        height: 16.875rem;
        padding: 1.1875rem 0.625rem;
      }
    }
  }

  &__company-detail {
    font-size: 1.125rem;
    line-height: 1.18125rem;
    letter-spacing: 0.0125rem;
  }

  &__founder-card-image {
    @include media('md-up') {
      width: 12rem;
      height: 16.875rem;
    }
  }

  &__founders-container--style-single {
    width: 50%;
  }
}

.FounderCard {
  height: auto;

  @include media('md-up') {
    height: 16.875rem;
  }
}
