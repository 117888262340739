.TextField {
  &--style-footer,
  &--style-module {
    input,
    textarea {
      @extend .secondary-sm;
      border: none;
      background-color: transparent;
      width: 100%;

      &:focus {
        outline: none;
      }
    }
  }

  &--style-footer {
    input,
    textarea {
      color: color('charcoal');

      &::placeholder {
        color: color('charcoal');
      }

      &:focus {
        outline: none;
      }
    }
  }

  &--style-module {
    input,
    textarea {
      color: color('chalk');

      &::placeholder {
        color: color('chalk');
      }
    }
  }
}
