.ParagraphWithHeadingAndButton {
  padding-bottom: 2.6875rem;

  &__paragraph {
    > div {
      display: inline;
      font-family: $univers;
      font-size: 0.875rem;
      line-height: 1.0625rem;

      @include media('lg-up') {
        font-size: 1.125rem;
        line-height: 1.375rem;
      }

      p {
        display: inline;
        padding-bottom: 2.6875rem;
      }
    }

    > p {
      display: inline;
      padding-bottom: 2.6875rem;
      font-family: $univers;
      font-size: 0.875rem;
      line-height: 1.0625rem;

      @include media('lg-up') {
        font-size: 1.125rem;
        line-height: 1.375rem;
      }
    }
  }
}
