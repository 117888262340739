.Nav {
  height: $nav-height-mobile;

  @include media('md-up') {
    height: $nav-height-tablet;
    padding-top: 0.8125rem;
  }
  @include media('xl-up') {
    height: $nav-height-desktop;
  }

  &__logo-bbgv {
    width: $home-hero-logo-width-mobile;

    @include media('md-up') {
      width: $home-hero-logo-width-desktop;
    }
  }

  &__logo-ventures {
    height: 5rem;

    @include media('md-up') {
      height: 8rem;
    }
  }

  &__logo-bbg {
    width: 5rem;

    @include media('md-up') {
      width: 7rem;
    }
  }

  &__logo-ventures {
    width: 5rem;

    @include media('md-up') {
      width: 10rem;
    }
  }

  &__logo-container {
    &--style-scrolled {
      max-width: 10rem;

      &:hover {
        max-width: $home-hero-logo-width-mobile;
      }

      @include media('md-up') {
        max-width: 6rem;

        &:hover {
          max-width: $home-hero-logo-width-desktop;
        }
      }
    }
  }

  .move-right {
    margin-left: 0.25rem;
    animation: animate-appear-from-left 0.45s forwards;

    @include media('md-up') {
      margin-left: 0.45rem;
    }
  }

  &__menu-overlay {
    z-index: -1;
  }
}
