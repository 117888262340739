.PressList {
  &--style-nutella {
    > .PressList__item {
      border-top: 2px solid color('nutella');
    }

    > .PressList__item:last-child {
      border-bottom: 2px solid color('nutella');
    }
  }

  &--style-lilac {
    > .PressList__item {
      border-top: 2px solid color('lilac');
    }

    > .PressList__item:last-child {
      border-bottom: 2px solid color('lilac');
    }
  }
}
