.EmailSignup {
  &--style-footer {
    &__inner-container {
      border-bottom: 2px solid color('charcoal');
    }
  }

  &--style-module {
    &__inner-container {
      border-bottom: 2px solid color('chalk');
    }
  }

  &__error {
    bottom: -1.5rem;
  }
}
