.TextModule {
  &--style-mulberry,
  &--style-default {
    &__paragraph p {
      display: inline;
      margin-right: 0.25rem;

      a {
        @extend .transition-shorter;
        font-weight: 600;
      }
    }
  }

  &--style-mulberry {
    &__paragraph p a,
    &__paragraph a {
      color: color('chalk');
    }

    &__button {
      &:hover {
        color: color('chalk');
      }
    }
  }

  &--style-default {
    &__paragraph p a,
    &__paragraph a {
      color: color('charcoal');

      &:hover {
        @extend .hover-lighten-charcoal;
      }
    }

    &__button {
      &:hover {
        color: color('charcoal');
      }
    }
  }

  &__inner-container {
    @include media('lg-up') {
      max-width: $site-inner-content-max-width;
    }
  }

  &__subheading-line {
    width: 4.9375rem;
    height: 0.1875rem;

    @include media('lg-up') {
      width: 5.75rem;
      height: 0.3125rem;
    }
  }

  &__heading,
  &__heading p {
    display: inline;
  }
}
